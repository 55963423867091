
  import { defineComponent, ref, onMounted, computed, reactive } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  // import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
  import AddSectionModal from "@/components/modals/forms/AddSectionModal.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from '@/store/enums/StoreEnums' 
  import { useStore } from "vuex";
  export default defineComponent({
    name: "language-listing",
    components: {
      Datatable,
      AddSectionModal,
      // ExportCustomerModal,
      // AddCustomerModal,
    },
    setup() {
      const store = useStore()
      const checkedCustomers = ref([]);
      const tableHeader = ref([
        // {
        //   key: "checkbox",
        //   sortable: false,
        // },
        {
          name: "Joylashuv",
          key: "zone",
          sortable: true,
        },
        {
          name: "Slug",
          key: "slug",
          sortable: true,
        },
        {
          name: "Turkum",
          key: "subcategory",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          name: "",
          key: "actions",
        },
      ]);
  

      const dataTable = computed(()=>{
        return store.getters.alSections
      })

  
      onMounted(async() => {
        setCurrentPageBreadcrumbs("Bo'limlar", []);
        await store.dispatch(Actions.ALL_SUBCATEGORIES, {params:{limit: 0}});
        await store.dispatch(Actions.ALL_CATEGORIES, {params:{limit: 0}});
        await store.dispatch(Actions.ALL_TYPEES);
        await store.dispatch(Actions.ALL_SECTIONS, {params:{limit: 10}});
      });


      const perPage = async(val:number)=>{
        await store.dispatch(Actions.ALL_SECTIONS, {params:{limit: val}});
      }

      const edit = ref<boolean>(false)

      const itemId = ref<string>("")

      const editItem = (item: string)=>{
        edit.value = true;
        itemId.value = item
        
      }
      const changeStatus = async(item: string, status: number)=>{
        await store.dispatch(Actions.CHANGE_SECTION_STATUS, {item, status});
      }
  
      const deleteFewCustomers = () => {
        checkedCustomers.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCustomers.value.length = 0;
      };
  
      const deleteCustomer = async(id:string) => {
        await store.dispatch(Actions.DEL_TAG, id);
      };
  
      const filter = reactive({
        search:'',
        category: '',
        subcat: ''
      });


      const searchItems = async() => {
        await store.dispatch(Actions.ALL_SECTIONS, {params:{title: filter.search, category: filter.category, subcategory: filter.subcat}});
      };


      const count = computed(()=>{
        return store.getters.getCount
      })

      const categories = computed(()=>{
        return store.getters.alCategories
      })

      const subcategories = computed(()=>{
        return store.getters.alSubategories
    })
      

      const clear = async()=>{
        filter.search = '';
        filter.category = '';
        filter.subcat = ''
        await store.dispatch(Actions.ALL_SECTIONS, {params: {}});
      }
      
      

      return {
        tableHeader,
        deleteCustomer,
        filter,
        searchItems,
        checkedCustomers,
        deleteFewCustomers,
        dataTable, edit, editItem, itemId, perPage, changeStatus, count, clear, categories, subcategories
      };
    },
  });
  