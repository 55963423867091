
  import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";
  import { hideModal } from "@/core/helpers/dom";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { Actions } from "@/store/enums/StoreEnums";
  import { useStore } from "vuex";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "add-section-modal",
    components: {},
    props: ['edit', 'item'],
    setup(props) {
      const formRef = ref<null | HTMLFormElement>(null);
      const addLanguageModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const loading = ref<boolean>(false);
      const add = ref<boolean>(true)
      const formData = ref({
        zone: "",
        slug: "",
        subcategory: [],
        category: null,
        type: ""
      });


      const resetForm = ()=>{
        formData.value.zone = "",
        formData.value.subcategory = []
        formData.value.category = null
        formData.value.slug = ""
        formData.value.type = ""
      }

      const swiching = ref("Category")
  
      const rules = ref({
        name: [
          {
            required: true,
            message: "Zone name is required",
            trigger: "blur",
          },
        ],
        subcategory: [
          {
            required: true,
            message: "Subcategory is required",
            trigger: "change",
          },
        ],
      });



      watchEffect(()=>{
        add.value = !props.edit
        if(!add.value){
          ApiService.get('section/getone/'+ props.item).then((res)=>{
              formData.value = res.data;
          })
        }else{
          resetForm()
        }
      })

  
      const submit = () => {
        if (!formRef.value) {
          return;
        }
  
        formRef.value.validate((valid) => {
          if (valid) {
            loading.value = true;
            if(add.value){
              store.dispatch(Actions.ADD_SECTION, formData);
            }else{
              store.dispatch(Actions.EDIT_SECTION, formData);
            }
            
            setTimeout(() => {
              loading.value = false;
  
              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                resetForm();
                hideModal(addLanguageModalRef.value);
                add.value = true
              });
            }, 200);
          } else {
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        });
      };
      
      const subcategories = computed(()=>{
        return store.getters.alSubategories
      })
      const categories = computed(()=>{
        return store.getters.alCategories
      })

      const typees = computed(()=>{
        return store.getters.alTypees
     })
  
     
      return {
        formData, rules, submit, formRef, loading,
        addLanguageModalRef, add, subcategories, typees, swiching, categories
      };
    },
  });
  